import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import Title from "../components/Title";
import * as styles from "../styles";
import {useConfig} from "../components/providers/ConfigProvider";
import ErrorPage from "../components/ErrorPage";
import {useSections} from "../components/providers/SectionsProvider";
import QuestionSection from "../components/config/question_edit/QuestionSection";
import DialogueBox from "../components/config/DialogueBox";

/**
 * The edit questions page. This page is used to edit the questions and answers for a section. After the changes have
 * been made, the user can save the changes at the bottom.
 *
 * @category Component
 * @see QuestionSection, DialogueBox
 */
const EditQuestions = () => {
  const {proxyUrl, proxyPort} = useConfig()
  const {sections, updateSection, isMobile} = useSections()

  const [params] = useSearchParams()
  const index = Number(params.get('section')) || 0

  const section = sections[index]

  const [questions, setQuestions] = useState(section?.questions)
  const [displayDialogue, setDisplayDialogue] = useState(false)
  const [saveSliders, setSaveSliders] = useState([false, false, false])
  const setSliders = saveSliders.map((_, i) => {
    return () => {
      const newSliders = [...saveSliders]
      newSliders[i] = !newSliders[i]
      setSaveSliders(newSliders)
    }
  })

  const [availableFiles, setAvailableFiles] = useState([] as string[])
  useEffect(() => {
    const fetchAllFiles = async () => {
      return await (await fetch(`${proxyUrl}:${proxyPort}/files`)).json()
    }
    fetchAllFiles().then(data => setAvailableFiles(data.files))
  }, [proxyPort, proxyUrl])

  if (!section) {
    return <ErrorPage/>
  }

  /**
   * Update the questions for this section on the backend. This also updates on Google forms.
   *
   * @param clearResponses - Whether to clear previous responses
   * @param linkSheet - Whether to link a new spreadsheet
   * @param updateForms - Whether to update Google Forms
   */
  const updateQuestions = async (clearResponses: boolean, linkSheet: boolean, updateForms: boolean) => {
    const newSection = {...section}
    newSection.questions = questions
    alert("Saving... please wait.")
    await updateSection(newSection, index, clearResponses, linkSheet, updateForms)
  }

  return (
    <DialogueBox
      title={"Save changes"}
      description={"Confirm some settings before saving"}
      cancel={() => setDisplayDialogue(false)}
      confirm={async () => {
        await updateQuestions(saveSliders[0], saveSliders[1], saveSliders[2])
        setDisplayDialogue(false)
      }}
      sliders={["Clear previous responses", "Link new spreadsheet", "Update questions on Google Forms"]}
      sliderStates={saveSliders}
      setSliders={setSliders}
      display={displayDialogue}
    >
      <div style={styles.bg}>
        <div style={{...styles.form, ...(isMobile ? styles.scaleMobile : styles.scaleNormal)}}>
          <Title
            title={"Edit Questions"}
            subTitle={"Update questions and answers for this section"}
            description={"Type to change any field, select the correct answer and click to save at the bottom. " +
              "The x can be used to remove options. Select blank to deselect a file."}
            secondaryTitle={"Quiz " + (index + 1)}
          />
          <button
            style={{...styles.button, marginBottom: 0, marginTop: 12}}
            onClick={() => { window.location.href = '/config' }}
          >
            Back to config
          </button>
          {questions.map((_, i) => {
              const moveQuestion = (direction: 'up' | 'down') => {
                const newSection = [...questions]
                if (direction === 'up' && i > 0) {
                  const temp = newSection[i - 1]
                  newSection[i - 1] = newSection[i]
                  newSection[i] = temp
                } else if (direction === 'down' && i < newSection.length - 1) {
                  const temp = newSection[i + 1]
                  newSection[i + 1] = newSection[i]
                  newSection[i] = temp
                }
                setQuestions(newSection)
              }
              return <QuestionSection
                index={i}
                key={i}
                question={questions[i]}
                availableFiles={availableFiles}
                setQuestion={
                  (newQuestions) => {
                    const newSection = [...questions]
                    newSection[i] = newQuestions
                    setQuestions(newSection)
                  }}
                deleteQuestion={() => {
                  const newSection = [...questions]
                  newSection.splice(i, 1)
                  setQuestions(newSection)
                }}
                duplicateQuestion={() => {
                  const newSection = [...questions]
                  const newQuestion = {...questions[i]}
                  newQuestion.choice = questions[i].choice ? {...questions[i].choice!} : undefined
                  newQuestion.scale = questions[i].scale ? {...questions[i].scale!} : undefined
                  newSection.splice(i, 0, newQuestion)
                  setQuestions(newSection)
                }}
                moveQuestion={moveQuestion}
              />
            }
          )}
          <button
            style={{...styles.button, marginBottom: 0, marginTop: 16}}
            onClick={() => {
              setQuestions([...questions, {
                title: "",
                files: [],
                type: "choice",
                required: false,
                points: 1,
                choice: {
                  options: [],
                  answers: []
                },
              }])
            }}
          >
            New Question
          </button>
          <div style={styles.footer}>
            <button style={styles.button} onClick={() => setDisplayDialogue(true)}>
              <div style={styles.submit}>
                Save changes
              </div>
            </button>
            <div
              style={styles.clearForm}
              onClick={() => {
                window.location.reload()
              }}
            >
              Reset Changes
            </div>
          </div>
        </div>
      </div>
    </DialogueBox>

  );
};

export default EditQuestions;