import {useAuth} from "./AuthProvider";
import {Navigate} from "react-router-dom";
import React, {JSX} from "react";

/**
 * Props for the `ProtectedPath` component.
 */
export type ProtectedPathProps = {
  /** The children components */
  children: JSX.Element;
};

/**
 * Renders the children if the user is authenticated, otherwise redirects to the auth page.
 *
 * @param props - Props for the ProtectedPath component
 * @category Component
 */
const ProtectedPath = ({children}: ProtectedPathProps) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate to={'/auth'} />;
};

export default ProtectedPath;