import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Form from './pages/form';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Config from "./pages/config";
import Auth from "./pages/auth";
import Magic from "./pages/magic";
import AuthProvider from "./components/providers/AuthProvider";
import ConfigProvider from "./components/providers/ConfigProvider";
import ProtectedPath from "./components/providers/ProtectedPath";
import EditQuestions from "./pages/edit-questions";
import SectionsProvider from "./components/providers/SectionsProvider";

/**
 * The main entry point for the application. This is where the routing is defined.
 */
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ConfigProvider>
        <SectionsProvider>
          <BrowserRouter>
            <Routes>
              <Route index path={'form'} element={<Form />} />
              <Route path={'auth'} element={<Auth />} />
              <Route path={'magic'} element={<Magic />} />
              <Route path={'config'} element={<ProtectedPath children={<Config />}/>} />
              <Route path={'edit-questions'} element={<ProtectedPath children={<EditQuestions />}/>}/>
              <Route path={'*'} element={<Navigate to={'/form?section=0'}/>} />
            </Routes>
          </BrowserRouter>
        </SectionsProvider>
      </ConfigProvider>
    </AuthProvider>
  </React.StrictMode>
);
