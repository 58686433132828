import React, {useState} from 'react';
import * as styles from "../../styles";

/** Props for the `RadioButton` component. */
export type RadioButtonProps = {
  /** The selection value of the button */
  type: string,
  /** The current selection state */
  selection: string,
  /** The function to set the selection state */
  setSelection: (type: string) => void,
  /** The theme colour of the button */
  theme: string,
}

/**
 * The radio button component. Multiple buttons are grouped together by sharing the same selection state.
 *
 * @param props - The props for the radio button component
 * @category Component
 * @see MultipleChoiceQuestion
 */
const RadioButton = ({type, selection, setSelection, theme}: RadioButtonProps) => {
  const [hover, setHover] = useState(false)
  return (
    <div
      className="radio" style={{marginBottom: 12}}
      onClick={() => {
        if (selection === type) {
          setSelection('')
        } else {
          setSelection(type)
        }
      }}
    >
      {hover && <span style={styles.hoverBg}/>}
      <span
        style={{
          ...styles.hoverButton,
          backgroundColor: selection === type ? theme : '#ffffff'
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      />
      <div style={{marginLeft: 32}}>
        {type.toString()}
      </div>
    </div>
  );
};

export default RadioButton;