import React, {useCallback, useEffect, useState} from 'react';
import Title from "../components/Title";
import axios from "axios";
import ConfigSection from "../components/config/ConfigSection";
import Upload from "../components/config/Upload";
import {useConfig} from "../components/providers/ConfigProvider";
import * as styles from "../styles";
import {useSections} from "../components/providers/SectionsProvider";

/**
 * The config page. This page is used to upload files and manage sections. The user can upload audio files and create
 * new sections here.
 *
 * @category Component
 * @see ConfigSection, Upload
 */
const Config = () => {
  const {proxyUrl, proxyPort} = useConfig()
  const {sections, addSection, isMobile} = useSections()
  const [upload, setUpload] = useState([] as File[])
  const [uploading, setUploading] = useState(false)
  const [allFiles, setAllFiles] = useState([] as string[])

  /**
   * Fetch all filenames from the backend data folder.
   */
  const fetchAllFiles = useCallback(async () => {
    let response;
    try {
      response = await fetch(`${proxyUrl}:${proxyPort}/files`)
    } catch (error) {
      response = await fetch(`http://localhost:${proxyPort}/files`)
    }
    return await response.json()
  }, [proxyPort, proxyUrl])

  useEffect(() => {
    fetchAllFiles().then((data) => setAllFiles(data.files))
  }, [fetchAllFiles]);

  /**
   * Save the uploaded files to the backend.
   */
  const saveFile = async () => {
    if (!upload) {
      alert('Please select a file')
      return
    }
    const formData = new FormData();
    upload.forEach(file => {
      formData.append('files', file)
    })

    setUploading(true)
    try {
      const headers = {headers: {'Content-Type': 'multipart/form-data'}}
      await axios.post(`${proxyUrl}:${proxyPort}/upload?path=/`, formData, headers)
      alert('Files uploaded successfully')
    } catch (error) {
      alert('Error uploading file: ' + error)
    }
    setUploading(false)
    await fetchAllFiles().then((data) => setAllFiles(data.files))
  }

  return (
    <div style={styles.bg}>
      <div style={{...styles.form, ...(isMobile ? styles.scaleMobile : styles.scaleNormal)}}>
        <Title
          title={"Config"}
          subTitle={"Upload files and manage sections"}
          description={""}
        />
        <div style={styles.section}>
          <div style={styles.sectionContainer}>
            <div>
              Upload audio files:
            </div>
            <Upload setSelected={setUpload} selected={upload} saveFile={saveFile} uploading={uploading} multiple={true}/>
            <div>
              Available files: (scroll to view more)
            </div>
            <div style={styles.scroll}>
              {allFiles.map((file, index) =>
                <div key={index} style={{fontSize: 13}}>
                  • {file}
                </div>
              )}
            </div>
          </div>
        </div>
        {sections.map((_, index) =>
          <ConfigSection key={index} index={index}/>
        )}
        <button
          style={{...styles.button, marginBottom: 12, marginTop: 12}}
          onClick={addSection}
        >
          New section
        </button>
      </div>
    </div>
  );
};

export default Config;
