import React, {useState} from 'react';
import * as styles from "../../../styles";

/** Props for the EditRadioButton component. */
export type EditRadioButtonProps = {
  /** The text and value for the radio button */
  type: string,
  /** The function to call to edit the radio button value */
  setType: (type: string) => void,
  /** The answers for the radio buttons */
  answers: string[],
  /** The function to call to edit the answers */
  setAnswers: (answers: string[]) => void,
}

/**
 * A radio button that can be edited. The radio button can be selected or deselected to select it as an answer.
 *
 * @param props - The props for the `EditRadioButton` component
 * @returns A React component for editing a radio button
 * @category Component
 * @see ButtonGroupEdit
 */
const RadioButtonEdit = ({type, setType, answers, setAnswers}: EditRadioButtonProps) => {
  const [hover, setHover] = useState(false)
  return (
    <div
      className="radio" style={{marginBottom: 12, marginRight: 12, ...styles.flexRow}}
    >
      {hover && <span style={styles.hoverBg}/>}
      <span
        style={{
          ...styles.hoverButton,
          backgroundColor: answers?.includes(type) ? '#673ab7' : '#ffffff',
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => {
          const newAnswers = answers ? [...answers] : []
          if (answers && answers.includes(type)) {
            newAnswers.splice(answers.indexOf(type), 1)
          } else {
            newAnswers.push(type)
          }
          setAnswers(newAnswers)
        }}
      />
      <input
        style={{...styles.editable, marginLeft: 32}}
        value={type}
        onChange={(event) => {
          const newAnswers = [...answers];
          const index = answers.indexOf(type);
          if (index !== -1) {
            newAnswers[index] = event.target.value;
            setAnswers(newAnswers);
          }
          setType(event.target.value)
        }}
      />
    </div>
  );
};

export default RadioButtonEdit;