import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import axios from "axios";
import {useAuth} from "../components/providers/AuthProvider";
import {useConfig} from "../components/providers/ConfigProvider";

/**
 * The magic link page. This page is used to verify the magic link token. If the token is valid, the user is
 * authenticated and redirected to the config page.
 * @category Component
 */
const Magic = () => {
  const { proxyUrl, proxyPort} = useConfig();

  const [params] = useSearchParams()
  const token = params.get('token') || '0'
  const [isValid, setIsValid] = useState(null as boolean | null);
  const navigate = useNavigate();

  const { login } = useAuth();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await axios.post(`${proxyUrl}:${proxyPort}/auth?token=${token}`);
        if (response.status === 200) {
          login();
          navigate('/config');
          setIsValid(true);
        } else {
          setIsValid(false);
        }
      } catch (err) {
        setIsValid(false);
      }
    };
    verifyToken();
  }, [token, login, navigate, proxyUrl, proxyPort]);
  return (
    <div>
      {isValid === null
        ? <div>Verifying token...</div>
        : <div>Invalid token</div>
      }
    </div>
  );
};

export default Magic;