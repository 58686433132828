import React from 'react';
import {Section} from "../../types/section";
import MultipleChoiceQuestion from "./MultipleChoiceQuestion";
import TextQuestion from "./TextQuestion";
import ScaleQuestion from "./ScaleQuestion";
import * as styles from "../../styles";
import {useConfig} from "../providers/ConfigProvider";

/**
 * Props for the Question components, including MultipleChoiceQuestion, TextQuestion, ScaleQuestion and Question.
 */
export type QuestionProps = {
  /** The index of the question */
  index: number,
  /** The section object this question belongs to */
  section: Section,
  /** The Google Forms entry id for this question */
  entry: string,
  /** The alert state */
  alert: boolean,
  /** The function to set the alert state */
  setAlert: (value: boolean) => void,
  /** The current selected value */
  selection: string,
  /** The function to set the selected value */
  setSelection: (value: string) => void,
}

/**
 * The question component. This is used to display all different types of questions, depending on their type.
 *
 * @param props - The props for the question component
 * @category Component
 * @see MultipleChoiceQuestion
 * @see TextQuestion
 * @see ScaleQuestion
 */
const Question = (props: QuestionProps) => {
  const {proxyUrl, proxyPort} = useConfig()
  const {section, index} = props;

  const question = section.questions[index];
  const fileLinks = question.files ? question.files.map((file) => {
      return file.startsWith('http')
        ? file
        : `${proxyUrl}:${proxyPort}/serve-file?path=${file}`
    }) : []


  return <div style={{...styles.section,
      border: props.alert ? '1px solid #dd3025' : '1px solid #e0e0e0'}}>
    <div style={styles.sectionContainer}>
      <div style={styles.titleContainer}>
        <div style={{...styles.text, display: 'flex'}}>
          <div>
            {index + 1}. {question.title}
          </div>
          {section.questions[index].required && <div style={{color: '#c00'}}>
            *
          </div>}
        </div>
        {question.type === 'choice' && <div style={styles.point}>
          {question.points} point{question.points !== 1 && 's'}
        </div>}
      </div>
      <div style={{display: "flex", width: "100%", justifyContent: "center", flexDirection: "column"}}>
        {fileLinks.map((file, i) =>
          <audio controls style={styles.audio}>
            <source src={file}/>
            Your browser does not support the audio element.
          </audio>)
        }
      </div>
      {question.type === 'choice' ?
        <MultipleChoiceQuestion {...props} /> :
        question.type === 'scale' ?
          <ScaleQuestion {...props} /> :
          question.type === 'text' ?
            <TextQuestion {...props} /> :
            <div>Unknown question type</div>}
    </div>
  </div>
};

export default Question;