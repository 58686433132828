import React, {JSX} from 'react';
import * as styles from "../../styles"
import ToggleSlider from "./ToggleSlider";

/** Props for the `DialogueBox` component. */
export type DialogueBoxProps = {
  /** The title of the dialogue box */
  title: string
  /** The description of the dialogue box */
  description: string
  /** The function to call when the cancel button is clicked */
  cancel: () => void
  /** The function to call when the confirm button is clicked */
  confirm: () => void
  /** The text for each slider */
  sliders?: string[]
  /** The state of each slider */
  sliderStates?: boolean[]
  /** The functions to call when a slider is toggled */
  setSliders?: (() => void)[]
  /** Whether the dialogue box should be displayed */
  display: boolean
  /** The components to display over */
  children: JSX.Element
}

/**
 * A dialogue box that can be used to confirm or cancel an action. The dialogue box can also contain sliders that can
 * be toggled on or off. To display on top of other components, the other components should be passed as children.
 *
 * @param props - The props for the `DialogueBox` component
 * @returns The dialogue box component
 * @category Component
 */
const DialogueBox = (
  {
    title, description, cancel, confirm,
    sliders = [], sliderStates = [], setSliders = [],
    display, children
  }: DialogueBoxProps) => {

  if (!display) {
    return children
  }

  return (
    <div>
      <div style={styles.dialogueBox}>
        <div style={{...styles.title, marginTop: 0}}>
          <div style={styles.bar}/>
          <div style={styles.titleBarContainer}>
            <div style={styles.titleText}>
              {title}
            </div>
          </div>
          <div style={styles.subTitleText}>
            {description}
          </div>
          <div style={{...styles.divider, marginBottom: 24}}/>
          {sliders.map((slider, i) => {
            return <div style={{marginBottom: 16, marginLeft: 32}}>
              <ToggleSlider isOn={sliderStates[i]} setIsOn={setSliders[i]} text={slider} />
            </div>
          })}
          <div style={styles.divider}/>
          <div style={{...styles.flexRow, width: 576, paddingLeft: 32}}>
            <button style={styles.button} onClick={cancel}>
              Cancel
            </button>
            <button style={styles.button} onClick={confirm}>
              Confirm
            </button>
          </div>
        </div>
      </div>
      <div style={styles.dialogueScreen}>
        {children}
      </div>
    </div>
  );
};

export default DialogueBox;