import React from 'react';
import * as styles from "../../styles";

/** Props for the `ToggleSlider` component. */
export type ToggleSliderProps = {
  /** The state of the toggle slider */
  isOn: boolean
  /** The function to call when the toggle slider is clicked */
  setIsOn: () => void
  /** The text to display next to the toggle slider */
  text: string
}

/**
 * A toggle slider that can be used to toggle a setting on or off.
 *
 * @param props - The props for the `ToggleSlider` component
 * @returns The toggle slider component
 * @category Component
 */
const ToggleSlider = ({isOn, setIsOn, text}: ToggleSliderProps) => {
  return (
    <div style={{...styles.flexRow, justifyContent: 'space-between'}}>
        <div onClick={setIsOn}>
          <div style={{...styles.toggleHandle, marginLeft: isOn ? 24 : 0}}/>
          <div style={styles.flexRow}>
            <div style={{...styles.togglePill, backgroundColor: isOn ? styles.primaryMuted: "#eee"}}/>
            <div style={{marginLeft: 4, ...styles.point}}>
              {text}
            </div>
          </div>
        </div>
    </div>
  );
};

export default ToggleSlider;