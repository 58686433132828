import React from 'react';
import * as styles from "../../../styles";

/**
 * A text area that can be edited.
 * @returns The text area component
 * @category Component
 */
const TextEdit = ({}) => {
  return (
    <div>
      <textarea
        style={{...styles.input, width: 556, resize: 'vertical', fontSize: 16, fontFamily: "Roboto, sans-serif"}}
      />
    </div>
  );
};

export default TextEdit;