import React from 'react';
import {Section} from "../../../types/section";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import * as styles from "../../../styles";

/** Props for the `ScaleEdit` component. */
export type EditScaleProps = {
  /** The question data */
  question: Section['questions'][0],
  /** The function to call to edit the question */
  setQuestion: (question: Section['questions'][0]) => void,
}

/**
 * A component for editing a scale question. The range and labels can be edited.
 *
 * @param props - The props for the `ScaleEdit` component
 * @returns A React component for editing a scale question
 * @category Component
 * @see QuestionSection
 */
const ScaleEdit = ({question, setQuestion}: EditScaleProps) => {
  const scale = question.scale!;
  return (
    <div>
      <div style={{...styles.flexRow, marginBottom: 12, marginTop: 12}}>
        <input
          type={'number'}
          style={{...styles.editableSmall, float: 'right'}}
          onChange={(e) => {
            const newQuestion = {...question}
            const low = Number(e.target.value)
            newQuestion.scale!.rangeLow = low < 0 ? 0 : low > 1 ? 1 : low
            setQuestion(newQuestion)
          }}
          value={scale.rangeLow}
        />
        <div style={{width: "200%"}}/>
        <input
          type={'number'}
          style={{...styles.editableSmall, float: 'right'}}
          onChange={(e) => {
            const newQuestion = {...question}
            const high = Number(e.target.value)
            newQuestion.scale!.rangeHigh = high > 10 ? 10 : high < scale.rangeLow ? scale.rangeLow : high
            setQuestion(newQuestion)
          }}
          value={scale.rangeHigh}
        />
      </div>
      <Slider min={scale.rangeLow} max={scale.rangeHigh} defaultValue={scale.rangeLow} step={1}
              style={{marginTop: 16, marginBottom: 4, color: styles.colourPrimary}}
              styles={{
                track: {backgroundColor: styles.colourPrimary},
                rail: {backgroundColor: '#e0e0e0'},
                handle: {
                  backgroundColor: styles.colourPrimary,
                  borderColor: styles.colourPrimary,
                  boxShadow: 'none',
                },
              }}
              dotStyle={{borderColor: styles.colourPrimary}}
      />
      <div style={{...styles.flexRow, marginBottom: 12}}>
        <input
          style={{...styles.editableSmall, textAlign: 'left'}}
          onChange={(e) => {
            const newQuestion = {...question}
            newQuestion.scale!.labelLow = e.target.value
            setQuestion(newQuestion)
          }}
          value={scale.labelLow}
        />
        <div style={{width: "200%"}}/>
        <input
          style={{...styles.editableSmall, float: 'right'}}
          onChange={(e) => {
            const newQuestion = {...question}
            newQuestion.scale!.labelHigh = e.target.value
            setQuestion(newQuestion)
          }}
          value={scale.labelHigh}
        />
      </div>
    </div>
  );
};

export default ScaleEdit;