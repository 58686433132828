import React from 'react';
import Title from "../components/Title";
import axios from "axios";
import {useConfig} from "../components/providers/ConfigProvider";
import * as styles from "../styles";
import {useSections} from "../components/providers/SectionsProvider";

/**
 * The authentication page. This page is used to authenticate the user before they can access the config pages. They can
 * enter their email to receive a link to authenticate.
 * @category Component
 */
const Auth = () => {
  const {proxyUrl, proxyPort} = useConfig();
  const {isMobile} = useSections();

  const [email, setEmail] = React.useState('');

  const handleSubmit = async () => {
    try {
      const response = await axios.get(`${proxyUrl}:${proxyPort}/auth`, {params: {email: email}});
      alert('Link sent to ' + email)
    } catch (error) {
      alert('Unauthorised email')
    }
  }

  return (
    <div style={styles.bg}>
      <div style={{...styles.form, ...(isMobile ? styles.scaleMobile : styles.scaleNormal)}}>
        <Title
          title={"Authenticate"}
          subTitle={"You must be authenticated to access the config page."}
          description={"Enter your email to have a link sent to you."}
        />
        <div style={styles.section}>
          <div style={styles.sectionContainer}>
            <div>
              Email:
            </div>
            <input
              type={'email'}
              value={email}
              style={styles.input}
              onChange={(event) => setEmail(event.target.value)}
            />
            <button
              style={styles.button}
              onClick={handleSubmit}
            >
              Send link
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;