import React from 'react';
import * as styles from "../styles";

/**
 * Props for the `Title` component.
 */
export type TitleProps = {
  /** Title of the page */
  title: string
  /** Secondary title of the page, such as the name of the section */
  secondaryTitle?: string
  /** Subtitle of the page */
  subTitle: string
  /** Description of the page */
  description: string
  /** Hex colour of the bar */
  theme?: string,
  /** Whether to display an alert message */
  alert?: boolean
}

/**
 * Page title component with a coloured bar
 *
 * @param props - Props for the title component
 * @category Component
 */
const Title = ({title, secondaryTitle = "", subTitle, description, theme = styles.colourPrimary, alert = false}: TitleProps) => {
  return (
    <div style={styles.title}>
      <div style={{...styles.bar, backgroundColor: theme}}/>
      <div style={styles.titleBarContainer}>
        <div style={styles.titleText}>
          {title}
        </div>
        {secondaryTitle && <div style={styles.secondaryTitle}>
          {secondaryTitle}
        </div>}
      </div>
      <div style={styles.subTitleText}>
        {subTitle}
      </div>
      <div style={styles.divider}/>
      <div style={styles.subTitleText}>
        {description}
      </div>
      {alert && <>
          <div style={styles.divider}/>
          <div style={{color: '#dd3025', marginLeft: 25}}>
              ⓘ Please answer all required questions
          </div>
      </>}
    </div>
  );
}


export default Title;