import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import * as styles from "../../styles";
import {useSections} from "../providers/SectionsProvider";
import SectionTitleEdit from "./question_edit/SectionTitleEdit";

/** Props for the ConfigSection component. */
export type ConfigSectionProps = {
  /** The index of the section */
  index: number,
}

/**
 * Component for editing a section's title, subtitle, description, theme, and form ID. The questions are edited in a
 * separate page.
 *
 * @param props - The props for the `ConfigSection` component
 * @returns A React component for editing a section's title, subtitle, description, theme, and form ID
 * @category Component
 * @see Config
 */
const ConfigSection = ({index}: ConfigSectionProps) => {
  const { sections, reload, updateSection, removeSection} = useSections()
  const section = sections[index]

  const [formId, setFormId] = useState(section.formId)
  const navigate = useNavigate()

  const [confirmDelete, setConfirmDelete] = useState(false)
  const deleteSection = async () => {
    if (confirmDelete) {
      removeSection(index)
      reload()
      alert('Section Deleted')
      window.location.reload()
    }
    setConfirmDelete(!confirmDelete)
  }
  
  return (
    <div style={styles.section}>
      <div style={styles.sectionContainer}>
        <div style={styles.sectionHeader}>
          <div>
            Quiz {index + 1}: {section.questions.length} questions
          </div>
          <button style={styles.buttonMuted} onClick={deleteSection}>
            {confirmDelete ? "Click again to delete" : "Delete section"}
          </button>
        </div>
        <div style={styles.divider}/>
        <SectionTitleEdit
          title={section.title}
          subTitle={section.subtitle}
          description={section.description}
          theme={section.theme}
          save={async (title, subTitle, description, theme) => {
            const newSection = {...section}
            newSection.title = title
            newSection.subtitle = subTitle
            newSection.description = description
            newSection.theme = theme
            alert("Updating... Please wait.")
            await updateSection(newSection, index, false, false, false)
          }}
        />
        <div style={{width: "100%"}}>
          <div style={{...styles.flexRow, marginTop: 8}}>
            <button
              style={styles.slimButton}
              onClick={() => window.open(`https://docs.google.com/forms/d/${section.formId}/edit`)}
            >
              View on Google Forms
            </button>
            <div>
              <button
                style={{...styles.slimButton, marginRight: 8}}
                onClick={() => {window.open(`https://docs.google.com/forms/d/${section.formId}/viewanalytics`)}}
              >
                Response Analytics
              </button>
              <button
                style={styles.slimButton}
                onClick={() => {
                  if (section.sheets) {
                    window.open(
                      `https://docs.google.com/spreadsheets/d/${section.sheets[section.sheets.length - 1]}/edit`
                    )
                  } else {
                    alert("A sheet will be linked after editing")
                  }
                }}
              >
                Results Sheet
              </button>
            </div>
          </div>
        </div>
        <div style={{width: "100%", display: 'flex', marginBottom: 8}}>
          <input
            type="text"
            style={{flex: 1}}
            value={formId}
            onChange={(e) => setFormId(e.target.value)}
          />
          <button onClick={async () => {
            const newSection = {...section}
            newSection.formId = formId
            await updateSection(newSection, index, false, true, true)
          }} style={styles.buttonMuted}>
            Link a different form (Form ID)
          </button>
        </div>
        <div style={styles.flexRow}>
        <button style={styles.button} onClick={() => navigate(`/edit-questions?section=${index}`)}>
            Edit questions
          </button>
          <button style={styles.button} onClick={() => navigate(`/form?section=${index}`)}>
            View quiz
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfigSection;