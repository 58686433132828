import { CSSProperties } from 'react'

export const colourPrimary = '#673ab7'
export const primaryMuted = '#aa95d1'

export const bg: CSSProperties = {
  backgroundColor: '#f0ebf8',
  display: 'flex',
  justifyContent: 'center',
  minHeight: '100vh',
  width: '100%',
}

export const button: CSSProperties = {
  padding: '10px 20px',
  backgroundColor: colourPrimary,
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  marginBottom: 16,
}

export const slimButton: CSSProperties = {
  ...button,
  padding: '5px 10px',
  marginBottom: 0,
}

export const buttonMuted: CSSProperties = {
  ...slimButton,
  backgroundColor: '#d8d8d8',
  color: '#919191',
}

export const submit: CSSProperties = {
  fontSize: '16px',
  fontFamily: 'Roboto, sans-serif',
}

export const form: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  transformOrigin: "top"
}

export const scaleMobile: CSSProperties = {
  transform: 'scale(0.94)',
}

export const scaleNormal: CSSProperties = {
  transform: 'scale(1)',
}

export const footer: CSSProperties = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  marginTop: 20,
}

export const clearForm: CSSProperties = {
  color: colourPrimary,
  fontSize: 14,
  fontWeight: '500',
  fontFamily: 'Open Sans, sans-serif',
  marginRight: 8,
  cursor: 'pointer',
  marginTop: 8,
}

export const section: CSSProperties = {
  backgroundColor: '#fff',
  maxWidth: 640,
  width: '100vw',
  borderRadius: 8,
  border: '1px solid #e0e0e0',
  marginTop: 12,
}

export const sectionContainer: CSSProperties = {
  borderRadius: 8,
  display: 'flex',
  gap: 8,
  flexDirection: 'column',
  padding: 32,
}

export const input: CSSProperties = {
  padding: 8,
  marginTop: 8,
  marginBottom: 8,
}

export const scroll: CSSProperties = {
  width: '100%',
  maxHeight: 240,
  overflowY: 'scroll',
  display: 'flex',
  flexDirection: 'column',
}

export const question: CSSProperties = {
  backgroundColor: '#fff',
  maxWidth: 640,
  width: '100vw',
  borderRadius: 8,
  marginTop: 12,
}

export const divider: CSSProperties = {
  borderTop: '1px solid #e0e0e0',
  width: '100%',
  marginTop: 12,
  marginBottom: 12,
}
export const sectionHeader: CSSProperties = {
  width: '100%',
  justifyContent: 'space-between',
  display: 'flex',
}

export const titleContainer: CSSProperties = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
}

export const text: CSSProperties = {
  marginBottom: 8,
}

export const audio: CSSProperties = {
  width: "100%",
  borderRadius: 20,
  marginBottom: 8,
}

export const point: CSSProperties = {
  color: '#8c7d78',
  fontSize: 12,
  fontFamily: 'Roboto, sans-serif',
  textAlign: 'right',
}

export const hoverBg: CSSProperties = {
  marginLeft: -11,
  marginTop: -8,
  width: 41,
  height: 41,
  borderRadius: 20,
  backgroundColor: '#f9f7fc',
  position: 'absolute' as 'absolute',
  zIndex: 0,
}

export const hoverButton: CSSProperties = {
  position: 'absolute' as 'absolute',
  height: 16,
  width: 16,
  borderRadius: 10,
  marginLeft: 0,
  marginTop: 2,
  zIndex: 1,
  border: '2px solid #ddd',
  cursor: 'pointer',
}

export const editable: CSSProperties = {
  marginLeft: 8,
  border: 0,
  borderBottom: '1px solid #673ab7',
  fontFamily: 'Roboto, sans-serif',
  fontSize: 16,
  outline: 'none',
  flex: 1,
}

export const editableSmall: CSSProperties = {
  color: '#8c7d78',
  fontSize: 12,
  fontFamily: 'Roboto, sans-serif',
  textAlign: 'right',
  border: 0,
  borderBottom: '1px solid #ccc',
  outline: 'none',
  width: '100%',
  backgroundColor: '#fff',
}

export const flexRow: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flex: 1,
}

export const title: CSSProperties = {
  backgroundColor: '#ffffff',
  maxWidth: 640,
  width: '100%',
  borderRadius: 8,
  marginTop: 11,
  border: '1px solid #e0e0e0',
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: 12,
}

export const titleText: CSSProperties = {
  fontSize: 32,
  fontWeight: '400',
  fontFamily: 'Roboto, sans-serif',
}

export const subTitleText: CSSProperties = {
  fontSize: 14,
  fontWeight: '400',
  marginLeft: 28,
  fontFamily: 'Open Sans, sans-serif',
}

export const bar: CSSProperties = {
  backgroundColor: colourPrimary,
  height: 10,
  width: '100%',
  borderRadius: '8px 8px 0 0',
}

export const titleBarContainer: CSSProperties = {
  display: 'flex',
  width: '100%',
  maxWidth: 588,
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 26px',
  marginTop: 16,
  marginBottom: 8,
}

export const secondaryTitle: CSSProperties = {
  fontSize: 24,
  fontWeight: '400',
  color: '#4a4a4a',
  fontFamily: 'Roboto, sans-serif',
}

export const togglePill: CSSProperties = {
  height: 12,
  width: 40,
  borderRadius: 6,
}

export const toggleHandle: CSSProperties = {
  position: 'absolute',
  height: 16,
  width: 16,
  borderRadius: 8,
  backgroundColor: colourPrimary,
  marginTop: -2,
}

export const dialogueScreen: CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: '#ddd',
  opacity: '40%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 999,
}

export const dialogueBox: CSSProperties = {
  position: 'fixed',
  width: 640,
  zIndex: 1000,
  top: '50%',
  left: '50%',
  translate: '-50% -50%',
  backgroundColor: '#fff',
  borderRadius: 8,
}

export const dropZone: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  border: '2px dashed #673ab7',
  borderRadius: 8,
  padding: 32,
  marginTop: 12,
  marginBottom: 12,
  cursor: 'pointer',
}

export const addButton: CSSProperties = {
  width: 32,
  height: 16,
  ...point,
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
}

export const colourPickerButton: CSSProperties = {
  ...slimButton,
  height: 24,
  marginLeft: 28,
  marginTop: 8,
  zIndex: 1,
}
