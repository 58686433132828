import React, {useState} from 'react';
import * as styles from "../../../styles";
import {ChromePicker, ColorResult} from 'react-color';

/** Props for the `SectionTitleEdit` component. */
export type SectionTitleEditProps = {
  /** The title of the section */
  title: string
  /** The subtitle of the section */
  subTitle: string
  /** The description of the section */
  description: string
  /** The theme colour of the section */
  theme: string
  /** The function to call to save the changes */
  save: (title: string, subTitle: string, description: string, theme: string) => void
}

/**
 * Component for editing the title, subtitle, and description of a section. Used in ConfigSection.
 *
 * @param props - The props for the `SectionTitleEdit` component
 * @returns A React component for editing the title, subtitle, and description of a section
 * @category Component
 * @see ConfigSection
 */
const SectionTitleEdit = ({title: title_, subTitle: subTitle_, description: description_, theme, save}: SectionTitleEditProps) => {

  const [title, setTitle] = useState(title_)
  const [subTitle, setSubTitle] = useState(subTitle_)
  const [description, setDescription] = useState(description_)
  const [colour, setColour] = useState(theme)
  const [showPicker, setShowPicker] = useState(false)

  return (
    <div style={styles.title}>
      <div style={{...styles.bar, backgroundColor: colour}}/>
      <div style={styles.titleBarContainer}>
        <input
          style={{...styles.editable, ...styles.titleText, flex: 0, marginLeft: 0}}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <button
          style={{...styles.slimButton, position: 'relative', left: -52, backgroundColor: colour}}
          onClick={() => save(title, subTitle, description, colour)}
        >
          Save Title
        </button>
      </div>
      <input
        style={{...styles.editable, ...styles.subTitleText, width: 520}}
        value={subTitle}
        onChange={(e) => setSubTitle(e.target.value)}
      />
      <div style={styles.divider}/>
      <textarea
        style={{...styles.editable, ...styles.subTitleText, width: 520, resize: 'vertical'}}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        wrap={'hard'}
      />
      <div style={styles.flexRow}>
        <button style={{...styles.colourPickerButton, backgroundColor: colour}}
                onClick={() => setShowPicker(!showPicker)}>
          Show colour picker
        </button>
        {showPicker && <div style={{position: "absolute"}}>
          <div style={{marginLeft: 164, marginTop: 8}}>
            <ChromePicker
              color={colour}
              onChangeComplete={(colour: ColorResult) => setColour(colour.hex)}
              disableAlpha={true}/>
          </div>
        </div>}
      </div>
    </div>
  );
}

export default SectionTitleEdit;