import React from 'react';
import * as styles from "../styles";
import Title from "./Title";

/**
 * Error page component. To be used as a fallback for invalid URLs
 *
 * @category Component
 */
const ErrorPage = () => {
  return (
    <div style={styles.bg}>
      <div style={styles.form}>
        <Title
          title={"Error"}
          subTitle={"Section not found"}
          description={"Please check the URL"}
        />
        <div style={{width: 640}}/>
      </div>
    </div>
  );
};

export default ErrorPage;