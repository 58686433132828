import React from 'react';
import * as styles from "../../styles";
import Slider from "rc-slider";
import {QuestionProps} from "./Question";

/**
 * The scale question component. A slider is used to select a value within a range. This should only be used in the
 * Question component.
 *
 * @param props - The props for question components
 * @category Component
 * @see Question
 */
const ScaleQuestion = ({index, entry, section, selection, setSelection, setAlert}: QuestionProps) => {
  const scale = section.questions[index].scale!;
  return (
    <>
      <Slider min={scale.rangeLow} max={scale.rangeHigh} defaultValue={scale.rangeLow} step={1}
              style={{marginTop: 16, marginBottom: 4, color: section.theme}}
              styles={{
                track: {backgroundColor: section.theme},
                rail: {backgroundColor: '#e0e0e0'},
                handle: {
                  backgroundColor: section.theme,
                  borderColor: section.theme,
                  boxShadow: 'none',
                },
              }}
              dotStyle={{borderColor: section.theme}}
              onChange={(value) => {
                setSelection(`${value}`)
                setAlert(false)
              }}
      />
      <div style={{...styles.flexRow, marginBottom: 12}}>
        <div style={{...styles.point, textAlign: 'left'}}>
          {scale.labelLow}
        </div>
        <div style={{...styles.point, float: 'right'}}>
          {scale.labelHigh}
        </div>
      </div>
      <input type={'hidden'} name={entry}
             value={(selection === '') ? scale.rangeLow : Number(selection)}
      />
    </>
  );
};

export default ScaleQuestion;