import React from 'react';
import * as styles from "../../../styles";
import {Section} from "../../../types/section";

/** Props for the `EditTitle` component. */
export type EditTitleProps = {
  /** The question data */
  question: Section['questions'][0],
  /** The function to call to edit the question */
  setQuestion: (question: Section['questions'][0]) => void,
  /** The index of the question in the section */
  index: number,
}

/**
 * A component for editing the title of a question.
 *
 * @param props - The props for the `EditTitle` component
 * @returns A React component for editing the title of a question
 * @category Component
 * @see QuestionSection
 */
const QuestionTitleEdit = ({question, setQuestion, index}: EditTitleProps) => {
  return (
    <div style={{...styles.titleContainer, marginTop: 12, marginBottom: 12}}>
      <div style={styles.flexRow}>
        <div style={{fontFamily: "Roboto, sans-serif", fontSize: 16, alignContent: "center"}}>
          {index + 1}.
        </div>
        <input
          style={styles.editable}
          value={question?.title}
          onChange={(e) => {
            const newQuestion = {...question}
            newQuestion.title = e.target.value
            setQuestion(newQuestion)
          }}
        />
      </div>
    </div>
  );
};

export default QuestionTitleEdit;