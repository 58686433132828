import React, {createContext, useCallback, useEffect, useState} from 'react';
import axios from "axios";
import {Section} from "../../types/section";
import {useConfig} from "./ConfigProvider";
import {colourPrimary} from "../../styles";
import * as styles from "../../styles";
import Title from "../Title";

/**
 * Props for the `SectionsProvider` component.
 */
export type SectionProviderProps = {
  /** The children components */
  children: React.ReactNode;
};

const SectionsContext = createContext({
  sections: [] as Section[],
  reload: () => {},
  updateSection: async (
    section: Section, index: number, clearResponses: boolean, linkSheet: boolean, updateForms: boolean
  ) => {},
  addSection: () => {},
  removeSection: (index: number) => {},
  isMobile: false,
});

/**
 * Sections provider component. The sections are fetched from the backend and provided to all children. It also provides
 * functions to update, add and remove sections. This component must be a child of the ConfigProvider, and all other
 * components should be children of this.
 *
 * @param props - Props for the SectionsProvider component
 * @category Component
 */
const SectionsProvider = ({children}: SectionProviderProps) => {
  const { proxyUrl, proxyPort } = useConfig();
  const [sections, setSections] = useState([] as Section[]);
  const [loaded, setLoaded] = useState(false);
  const isMobile = window.innerWidth <= 640

  /**
   * Fetches the sections from the backend and updates the sections variable.
   */
  const reload = useCallback(async () => {
    try {
      const response = await axios.get(`${proxyUrl}:${proxyPort}/sections`);
      setSections(response.data);
      setLoaded(true);
    } catch (err) {
      console.error(err);
    }
  }, [proxyUrl, proxyPort]);

  useEffect(() => {
    reload().then(_ => {})
  }, [reload])

  /**
   * Updates a section on the backend and reloads the sections.
   *
   * @param section - The section data to be used
   * @param index - The index of the section to update
   * @param clearResponses - Whether to clear the responses associated
   * @param linkSheet - Whether to link a new Google Sheet
   * @param updateQuestions - Whether to update the Google Form
   */
  const updateSection = async (
    section: Section,
    index: number,
    clearResponses: boolean,
    linkSheet: boolean,
    updateQuestions: boolean,
  ) => {
    try {
      const newSections = [...sections]
      newSections[index] = section
      await axios.post(`${proxyUrl}:${proxyPort}/update-section`, newSections, {
        params: {
          index: index,
          clearResponses: clearResponses.toString(),
          linkSheet: linkSheet.toString(),
          updateQuestions: updateQuestions.toString()
        }
      })
      await reload()
      alert('Questions updated. Please set Release grades to: Immediately after each submission on Google Forms.')
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Adds a new section to the backend and reloads the sections.
   * The section will have the default values for the Deepfake Challenge.
   */
  const addSection = async () => {
    try {
      const formId: string = (await axios.get(`${proxyUrl}:${proxyPort}/new-form`)).data
      const newSections: Section[] = [...sections, {
        formId: formId,
        title: 'New Quiz',
        subtitle: 'Quiz Subtitle (Optional)',
        description: 'Quiz description (Optional)',
        theme: colourPrimary,
        questions: [],
        sheets: [],
        analytics: ""
      }];
      await axios.post(`${proxyUrl}:${proxyPort}/update-section`, newSections)
      await reload()
      alert("New section added")
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Removes a section from the backend and reloads the sections.
   *
   * @param index - The index of the section to remove
   */
  const removeSection = async (index: number) => {
    const newSections = [...sections]
    newSections.splice(index, 1)
    try {
      await axios.post(`${proxyUrl}:${proxyPort}/update-section`, newSections)
    } catch (error) {
      alert('Error deleting section: ' + error)
      console.error(error)
    }
    await reload()
  }

  if (!loaded) {
    return <div style={styles.bg}>
      <div style={{...styles.form, ...(isMobile ? styles.scaleMobile : styles.scaleNormal)}}>
        <Title title={"Loading..."}
               subTitle={"Please wait..."}
               description={"If you are stuck on this screen, make sure you are accessing via HTTPS."}
        />
        <div style={{width: 640}}/>
      </div>
    </div>
  }

  return (
    // The ConfigContext.Provider component will provide the authentication state and functions to all children
    <SectionsContext.Provider value={{sections, reload, updateSection, addSection, removeSection, isMobile}}>
      {children}
    </SectionsContext.Provider>
  );
};

// The useAuth hook can be used to access the authentication state and functions
export const useSections = () => React.useContext(SectionsContext);

export default SectionsProvider;