import React, {useState} from 'react';
import {Section} from '../../../types/section';
import * as styles from "../../../styles";
import MultipleChoiceEdit from "./MultipleChoiceEdit";
import ScaleEdit from "./ScaleEdit";
import TextEdit from "./TextEdit";
import QuestionTitleEdit from "./QuestionTitleEdit";
import {FaAngleDown, FaAngleUp} from "react-icons/fa";
import ToggleSlider from "../ToggleSlider";

/** Props for the EditQuestionSection component. */
export type EditQuestionSectionProps = {
  /** The question data */
  question: Section['questions'][0],
  /** The function to call to edit the question */
  setQuestion: (question: Section['questions'][0]) => void,
  /** The function to call to delete the question */
  deleteQuestion: () => void,
  /** The function to call to duplicate the question */
  duplicateQuestion: () => void,
  /** The function to call to move the question up or down */
  moveQuestion: (direction: 'up' | 'down') => void,
  /** The index of the question in the section */
  index: number,
  /** The files that are available to be added to the question */
  availableFiles: string[]
}

/**
 * A section for editing a question. This is used for all different types of questions.
 *
 * @param props - The props for the `EditQuestionSection` component
 * @returns A React component for editing a question
 * @category Component
 * @see MultipleChoiceEdit, ScaleEdit, TextEdit, QuestionTitleEdit
 */
const QuestionSection = ({
  question, setQuestion, deleteQuestion, duplicateQuestion, index, availableFiles, moveQuestion
}: EditQuestionSectionProps) => {

  const [link, setLink] = useState("")

  return (
    <div style={styles.question}>
      <div style={{...styles.sectionContainer, border: '1px solid #e0e0e0'}}>
        <div style={{...styles.flexRow, justifyContent: 'space-between'}}>
          <ToggleSlider isOn={question.required} setIsOn={() => {
            const newQuestion = {...question}
            newQuestion.required = !question.required
            setQuestion(newQuestion)
          }} text={"Required"}/>
          {question.type === 'choice' && <div style={{...styles.flexRow, flex: 0}}>
            <div style={{...styles.point, display: 'flex', paddingTop: 4}}>
              Points
            </div>
            <input
              type={'number'}
              style={{...styles.editableSmall, float: 'right', width: 40, marginRight: 8}}
              onChange={(e) => {
                const newQuestion = {...question}
                newQuestion.points = Number(e.target.value)
                setQuestion(newQuestion)
              }}
              value={question.points}
            />
          </div>}
          <div>
            <FaAngleDown onClick={() => {
              moveQuestion('down')
            }}/>
            <FaAngleUp onClick={() => {
              moveQuestion('up')
            }}/>
          </div>
        </div>
        <QuestionTitleEdit question={question} setQuestion={setQuestion} index={index}/>
        <div style={styles.flexRow}>
          <div style={{width: 240, textAlign: "left"}}>
            Question Type
          </div>
          <select
            style={{...styles.editableSmall, borderBottom: 0}}
            onChange={(e) => {
              const newQuestion = {...question}
              newQuestion.type = e.target.value as Section['questions'][0]['type']
              if (newQuestion.type === 'choice') { newQuestion.choice = {options: [], answers: []} }
              else { delete newQuestion.choice }
              if (newQuestion.type === 'scale') {
                newQuestion.scale = {labelLow: '', labelHigh: '', rangeLow: 1, rangeHigh: 10}
              }
              else { delete newQuestion.scale }
              setQuestion(newQuestion)
            }}
            value={question.type}
          >
            <option style={styles.point} value={"choice"}>Multiple Choice</option>
            <option style={styles.point} value={"scale"}>Scale</option>
            <option style={styles.point} value={"text"}>Text</option>
          </select>
        </div>
        <div style={{alignItems: "right", display: "flex", flexDirection: "column"}}>
          {(question.files ? [...question.files, ""] : [""]).map((file, i) =>
            <div style={{display: 'flex', flexDirection: 'row', marginBottom: 2}} key={i}>
              <div style={{...styles.point, borderBottom: "1px solid #ccc", width: 52, textAlign: "left"}}>
                {i !== question.files?.length ? `File ${i + 1}` : "Add file"}
              </div>
              <select
                style={styles.editableSmall}
                onChange={(e) => {
                  const newQuestion = {...question}
                  newQuestion.files = question.files ? [...question.files] : []
                  if (e.target.value === "") {
                    newQuestion.files.splice(i, 1)
                  } else {
                    newQuestion.files[i] = e.target.value
                  }
                  setQuestion(newQuestion)
                }}
                value={file}
              >
                <option/>
                <option>
                  {file}
                </option>
                {availableFiles.filter((f) => f !== file).map((file) =>
                  <option key={file} value={file}>
                    {file}
                  </option>
                )}
              </select>
            </div>
          )}
          <div style={styles.flexRow}>
            <div style={{...styles.editableSmall, width: 64, textAlign: "left"}}>
              Use a link
            </div>
            <input
              style={styles.editableSmall}
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
            <button style={styles.addButton} onClick={() => {
              const newQuestion = {...question}
              newQuestion.files = question.files ? [...question.files, link] : [""]
              setQuestion(newQuestion)
              setLink("")
            }}>+</button>
          </div>
        </div>
        {question.type === 'choice' && <MultipleChoiceEdit question={question} setQuestion={setQuestion}/>}
        {question.type === 'scale' && <ScaleEdit question={question} setQuestion={setQuestion} />}
        {question.type === 'text' && <TextEdit />}
        <div style={styles.flexRow}>
          <div>
            <button
              style={{...styles.slimButton, marginRight: 8}}
              onClick={duplicateQuestion}
            >
              Duplicate question
            </button>
            <button
              style={styles.buttonMuted}
              onClick={deleteQuestion}
            >
              Delete question
            </button>
          </div>
          {question.type === 'choice' && <button
            style={styles.slimButton}
            onClick={() => {
              setQuestion({...question, choice: {...question.choice!, options: [...question.choice!.options, ""]}})
            }}
          >
            Add New Option
          </button>}
        </div>
      </div>
    </div>
  );
};

export default QuestionSection;