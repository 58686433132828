import React, {useEffect, useState} from 'react';
import Question from "../components/form/Question";
import Title from "../components/Title";
import {useSearchParams} from "react-router-dom";
import axios from "axios";
import {useConfig} from "../components/providers/ConfigProvider";
import * as styles from "../styles";
import ErrorPage from "../components/ErrorPage";
import {useSections} from "../components/providers/SectionsProvider";

/**
 * The form page. This page is used to display the form for a section. The user can fill out the form and submit it.
 *
 * @category Component
 */
const Form = () => {
  const {proxyUrl, proxyPort} = useConfig()
  const {sections, isMobile} = useSections()

  const [params] = useSearchParams()
  const index = Number(params.get('section')) || 0

  const sectionConfig = sections[index] || null
  const [loading, setLoading] = useState(true)

  const link = `https://docs.google.com/forms/d/${sectionConfig?.formId}`
  const submitLink = `${link}/formResponse`

  // Google Forms uses inputs with names like "entry.<number>" for form entries
  // These can be found in the HTML of their view form page.
  const [entries, setEntries] = useState([] as string[])

  useEffect(() => {
    // Extract entry numbers from the form and add the corresponding file links
    const extractEntries = async () => {
      if (index >= sections.length) {
        return [] as string[]
      }

      // Fetch the form page (HTML) and extract entry numbers
      // We need to use a proxy because of CORS
      const response = await axios.get(`${proxyUrl}:${proxyPort}/proxy?url=${link}/viewform`)
      const doc = new DOMParser().parseFromString(response.data, 'text/html')
      const dataElements = doc.querySelectorAll('div[data-params]')
      // These entry numbers can be found in the data-params attribute
      const allIds = Array.from(dataElements)
        .map((element) => (
          (element.getAttribute('data-params') || '')
            .match(/\b\d+\b/g) || [])[2]
        )

      if (allIds.length > sectionConfig.questions.length) {
        throw new Error('Number of entries is greater than number of questions.\n' +
          'Please make sure the Google Form and config.json are in sync.\n' +
          'Each form entry should have a corresponding file link.')
      }

      const entries: string[] = allIds.map((id) => {
        if (!id) {
          throw new Error('Entry name not found')
        }
        return `entry.${id}`
      })
      return entries as string[]
    }
    extractEntries()
      .then((entries) => {
        setEntries(entries)
      })
  }, [proxyPort, proxyUrl, index, sections.length, link, sectionConfig?.questions.length]);

  useEffect(() => {
    setAlerts(entries.map(() => false))
    setSelections(entries.map(_ => ''))
    setLoading(entries.length === 0)
  }, [entries]);

  const [alerts, setAlerts] = useState([] as boolean[])
  const [selections, setSelections] = useState([] as string[])
  const [isSubmitted, setIsSubmitted] = useState(false)

  if (!sectionConfig) {
    return <ErrorPage/>
  }

  if (loading) {
    return <div style={styles.bg}>
      <div style={{...styles.form, ...(isMobile ? styles.scaleMobile : styles.scaleNormal)}}>
        <Title title={"Loading..."} subTitle={"Please wait"} description={""}/>
        <div style={{width: 640}}/>
      </div>
    </div>
  }

  return (
    <div className="App" style={styles.bg}>
      <form
        action={submitLink} onSubmit={() => setIsSubmitted(true)}
        style={{...styles.form, ...(isMobile ? styles.scaleMobile : styles.scaleNormal)}}
      >
        <Title
          title={sectionConfig.title}
          secondaryTitle={""}
          subTitle={sectionConfig.subtitle}
          description={sectionConfig.description}
          theme={sectionConfig.theme}
          alert={alerts.some((value) => value)}
        />
        <div>
          {entries.map((entry, index) =>
            <Question
              index={index}
              entry={entry}
              section={sectionConfig}
              alert={alerts[index]}
              setAlert={
                (value) => {
                  const newAlerts = [...alerts]
                  newAlerts[index] = value
                  setAlerts(newAlerts)
                }}
              selection={selections[index]}
              setSelection={
                (value) => {
                  const newSelections = [...selections]
                  newSelections[index] = value
                  setSelections(newSelections)
                }} key={index}/>
          )}
        </div>
        <div style={styles.footer}>
          {selections.some((value, i) => sectionConfig.questions[i].required && value === '')
            ? <button type="button" style={{...styles.button, backgroundColor: sectionConfig.theme}}
                      onClick={() => {
                        setAlerts(selections.map((value, i) =>
                          sectionConfig.questions[i].required && value.length === 0))
                        window.scrollTo(0, 0)
                      }}>
              <div style={styles.submit}>
                Submit
              </div>
            </button>
            : <button type="submit"
                      style={{...styles.button, backgroundColor: sectionConfig.theme}}
                      disabled={isSubmitted}>
              <div style={styles.submit}>
                Submit
              </div>
            </button>}
          <div
            style={{...styles.clearForm, color: sectionConfig.theme}}
            onClick={() => {
              setSelections(entries.map(_ => ''))
              setAlerts(entries.map(() => false))
              window.scrollTo(0, 0)
            }}>
            Clear Form
          </div>
        </div>
      </form>
    </div>
  );
}

export default Form;
