import React, {createContext, useEffect, useState} from 'react';

/**
 * Props for the `ConfigProvider` component.
 */
export type ConfigProviderProps = {
  /** The children components */
  children: React.ReactNode;
};

// These default values will never be used
const ConfigContext = createContext({
  proxyUrl: "none" as string,
  proxyPort: 0 as number,
  reload: () => {},
});

/**
 * Configuration provider component. The proxy port and URL are defined here, and provided to all children. It also
 * fetches the configuration from the backend and provides a reload function. All components should be wrapped in this.
 *
 * @param props - Props for the ConfigProvider component
 * @category Component
 */
const ConfigProvider = ({children}: ConfigProviderProps) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    reload()
  }, []);

  const proxyUrl = "https://forms2.oxfordwaveresearch.com"
  const proxyPort = 443

  /**
   * Fetches the configuration from the backend and updates the config variable.
   */
  const reload = async () => {
    try {
      setLoaded(true);
    } catch (err) {
      console.error(err);
    }
  }

  if (!loaded) {
    return <div>Loading...</div>;
  }

  return (
    // The ConfigContext.Provider component will provide the authentication state and functions to all children
    <ConfigContext.Provider value={{ proxyUrl, proxyPort, reload }}>
      {children}
    </ConfigContext.Provider>
  );
};

// The useAuth hook can be used to access the authentication state and functions
export const useConfig = () => React.useContext(ConfigContext);

export default ConfigProvider;