import * as styles from "../../../styles";
import ButtonGroupEdit from "./ButtonGroupEdit";
import React from "react";
import {Section} from "../../../types/section";

/** Props for the `MultipleChoiceEdit` component. */
export type EditChoiceProps = {
  /** The question data */
  question: Section['questions'][0],
  /** The function to call to edit the question */
  setQuestion: (question: Section['questions'][0]) => void,
}

/**
 * An edit component for multiple choice questions. The answer is displayed and the options can be edited.
 *
 * @param props - The props for the `MultipleChoiceEdit` component
 * @category Component
 * @returns A React component for editing a multiple choice question
 * @see ButtonGroupEdit, QuestionSection
 */
const MultipleChoiceEdit = ({question, setQuestion}: EditChoiceProps) => {
  const choice = question.choice!;
  return <div>
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 8}}>
      <div style={styles.point}>
        Answer
      </div>
      <div style={styles.point}>
        {choice.answers.join(", ")}
      </div>
    </div>
    <ButtonGroupEdit
      options={choice.options}
      setOptions={(options) => {
        const newQuestion = {...question}
        newQuestion.choice!.options = options
        setQuestion(newQuestion)
      }}
      answers={choice.answers}
      setAnswers={(answers) => {
        const newQuestion = {...question}
        newQuestion.choice!.answers = answers
        setQuestion(newQuestion)
      }}
    />
  </div>
}

export default MultipleChoiceEdit;