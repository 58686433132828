import React from 'react';
import * as styles from "../../styles";
import {QuestionProps} from "./Question";

/**
 * The text question component. A text area is used to input text. This should only be used in the Question component.
 *
 * @param props - The props for question components
 * @category Component
 * @see Question
 */
const TextQuestion = ({entry, selection, setSelection, setAlert}: QuestionProps) => {
  return (
    <>
      <textarea
        name={entry}
        style={{...styles.input, resize: 'vertical', fontSize: 16, fontFamily: "Roboto, sans-serif",}}
        value={selection}
        onChange={(e) => {
          setSelection(e.target.value)
          setAlert(false)
        }}
      />
    </>
  );
};

export default TextQuestion;