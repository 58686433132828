import RadioButton from "./RadioButton";
import {QuestionProps} from "./Question";
import React from "react";

/**
 * The multiple choice question component. This should only be used in the Question component.
 *
 * @param props - The props for question components
 * @category Component
 * @see Question
 */
const MultipleChoiceQuestion = ({index, entry, setAlert, selection, setSelection, section}: QuestionProps) => {
  const {choice} = section.questions[index]

  const setValue = (value: string) => {
    setSelection(value)
    setAlert(false)
  }

  return (
    <>
      <div>
        {choice!.options.map((option, i) =>
          <RadioButton
            type={option}
            selection={selection}
            setSelection={setValue}
            key={i}
            theme={section.theme}
          />
        )}
      </div>
      <input type="hidden" name={entry} value={selection}/>
    </>
  );
};

export default MultipleChoiceQuestion;
