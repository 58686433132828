import React, {useEffect} from 'react';
import RadioButtonEdit from "./RadioButtonEdit";
import * as styles from "../../../styles";
import { RxCross2 } from "react-icons/rx";

/** Props for the `ButtonGroupEdit` component. */
export type EditButtonGroupProps = {
  /** The options for the radio buttons */
  options: string[],
  /** The function to call to edit the options */
  setOptions: (options: string[]) => void,
  /** The answers for the radio buttons */
  answers: string[],
  /** The function to call to edit the answers */
  setAnswers: (answers: string[]) => void,
}

/**
 * A group of radio buttons that can be edited. The radio buttons can be added or removed. Answers are set by selecting
 * the radio buttons.
 *
 * @param props - The props for the `ButtonGroupEdit` component
 * @category Component
 * @returns A React component for editing a group of radio buttons
 * @see RadioButtonEdit, QuestionSection, MultipleChoiceEdit
 */
const ButtonGroupEdit = ({options, setOptions, answers, setAnswers}: EditButtonGroupProps) => {
  useEffect(() => {
    if (!options) {
      setOptions([])
    }
  }, [options, setOptions]);

  if (!options) {
    return <div>Loading...</div>
  }

  return (
    <div style={{marginTop: 8}}>
      {options.map((option, index) =>
        <div style={styles.flexRow} key={index}>
          <RadioButtonEdit
            type={option}
            setType={(type) => {
              const newOptions = [...options]
              newOptions[index] = type
              setOptions(newOptions)
            }}
            answers={answers}
            setAnswers={setAnswers}
          />
          <RxCross2
            onClick={() => {
              const newOptions = options.filter((_, i) => i !== index)
              setOptions(newOptions)
            }}
            cursor={"pointer"}
            color={"#444"}
          />
        </div>
      )}
    </div>
  );
};

export default ButtonGroupEdit;