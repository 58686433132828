import React, {useRef, DragEvent} from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import * as styles from "../../styles";

/** Props for the `Upload` component. */
export type UploadProps = {
  /** The function to call when files are selected */
  setSelected: (files: File[]) => void
  /** The selected files */
  selected: File[]
  /** The function to call to save the files */
  saveFile: () => Promise<void>
  /** Whether the files are currently being uploaded */
  uploading: boolean
  /** Whether multiple files can be selected */
  multiple?: boolean
}

/**
 * A component for uploading files. The files can be selected by clicking the "Select files" button or by dragging and
 * dropping files into the drop zone. The selected files can be cleared by clicking the "Clear selected" button. The
 * files can be uploaded by clicking the "Upload" button.
 *
 * @param props - The props for the `Upload` component
 * @returns A component for uploading files
 * @category Component
 */
const Upload = ({setSelected, selected, saveFile, uploading, multiple = false}: UploadProps) => {

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer) {
      const droppedFiles = Array.from(e.dataTransfer.files); // Convert FileList to an array
      setSelected(([...selected, ...droppedFiles]))
    }
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = 'copy'; // Show the copy icon when dragging files
  };

  return (
    <div>
      <div onDrop={handleDrop} onDragOver={handleDragOver} style={styles.dropZone}>
        {selected.length > 0 ? selected.map((file) => (file.name)).join(', ') : 'Drag files here'}
      </div>
      <div style={styles.flexRow}>
        <input multiple={multiple}
               type={"file"}
               ref={fileInputRef}
               style={{display: "none"}}
               onChange={(e) =>
                 setSelected(e.target.files ? Array.from(e.target.files) : [])}
        />
        <div>
          <button style={styles.slimButton} onClick={() => {
            if (fileInputRef.current) fileInputRef.current.click()
          }}>
            Select files
          </button>
          <button style={{...styles.slimButton, marginLeft: 8}} onClick={() => setSelected([])}>
            Clear selected
          </button>
        </div>
        <div>
          <button onClick={async () => {
            await saveFile()
            setSelected([])
          }} style={styles.slimButton}>
            Upload
          </button>
          {uploading && <div style={{alignContent: 'center'}}>
            <div>Uploading...</div>
            <ClipLoader size={16} color={"#673ab7"} loading={uploading}/>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default Upload;